import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const TermsOfUseModal = ({ show, onAccept }) => {
  return (
    <Modal 
      show={show} 
      backdrop="static" 
      keyboard={false}
      centered
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Modal.Header>
        <Modal.Title>Terms of Use Agreement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Welcome to RedBook! Before proceeding, please review and accept our Terms of Use.</p>
        <p>
          By clicking "I Accept" below, you agree to be bound by our{' '}
          <a 
            href="/RedBook Dashboard - Terms of Use - December 2024.pdf" 
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>
          .
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant="primary" 
          onClick={onAccept}
          style={{ 
            backgroundColor: '#71AD48', 
            borderColor: '#71AD48',
            color: '#FFFFFF',
            transition: 'background-color 0.2s'
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = '#5C8C3A';
            e.currentTarget.style.borderColor = '#5C8C3A';
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = '#71AD48';
            e.currentTarget.style.borderColor = '#71AD48';
          }}
        >
          I Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsOfUseModal;