import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import supabase from '../services/supabase';
import logo from './IPTalonsLogo.png';
import "../App.css";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyRecovery = async () => {
      try {
        // Check if we have a session
        const { data: { session } } = await supabase.auth.getSession();
        
        if (!session) {
          const urlParams = new URLSearchParams(location.search);
          const confirmationUrl = urlParams.get('confirmation_url');
          
          if (confirmationUrl) {
            const decodedUrl = decodeURIComponent(confirmationUrl);
            const confirmationUrlParams = new URL(decodedUrl).searchParams;
            const token = confirmationUrlParams.get('token');
            
            if (token) {
              const { error } = await supabase.auth.verifyOtp({
                token_hash: token,
                type: 'recovery'
              });
              
              if (error) throw error;
              
              // Clear the URL parameters
              window.history.replaceState(null, '', '/reset-password');
            }
          }
        }
      } catch (err) {
        console.error('Recovery verification error:', err);
        setError('Invalid or expired reset link. Please request a new one.');
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } finally {
        setLoading(false);
      }
    };

    verifyRecovery();
  }, [navigate, location]);

  const handleReset = async (e) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters");
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;

      setSuccess(true);
      
      // Sign out after successful password reset
      await supabase.auth.signOut();
      
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return (
      <div className="login-wrapper">
        <div className="login-container">
          <div className="login-logo-container">
            <img src={logo} alt="IPT Logo" className="login-logo" />
          </div>
          <div style={{ textAlign: 'center', padding: '20px' }}>
            Loading...
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-logo-container">
          <img src={logo} alt="IPT Logo" className="login-logo" />
        </div>
        <h2 className="login-title">Reset Your Password</h2>
        <div className="login-form-container">
          <div className="login-form-card">
            <input
              type="password"
              className="login-input"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              type="password"
              className="login-input"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button className="login-button" onClick={handleReset}>
            Reset Password
          </button>
          {error && (
            <div style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>
              {error}
            </div>
          )}
          {success && (
            <div style={{ color: 'green', marginTop: '10px', textAlign: 'center' }}>
              Password reset successful! Redirecting to login...
            </div>
          )}
        </div>
      </div>
      <div className="login-footer">
        &copy; 2025 IPTalons, Inc. All rights reserved.
      </div>
    </div>
  );
};

export default ResetPassword;