import supabase from "../services/supabase";
import React, { useEffect, useState, useCallback } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { AdminInterface } from "./AdminInterface";
import Modal from "react-bootstrap/Modal";
import logo from './IPTalonsLogo.png';
import "../App.css";
import TrialBanner from './TrialBanner';
import ProgressBanner from "./ProgressBanner";
import TermsOfUseModal from "./TermsOfUseModal";

const Home = ({ user }) => {
  const [selectedDashboard, setSelectedDashboard] = useState("Dashboard");
  const [customLinks, setCustomLinks] = useState([]);
  const [driveUrl, setDriveUrl] = useState(null);
  const [parameter, setParameter] = useState(null);
  const [variant, setVariant] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [accountType, setAccountType] = useState("paid");
  const [trialEndsAt, setTrialEndsAt] = useState(null);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [currentIframeUrl, setCurrentIframeUrl] = useState('');
  const [showTermsModal, setShowTermsModal] = useState(false);
  // eslint-disable-next-line
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(true); // Default to true, will be updated from DB

  const customizeIframe = useCallback(() => {
    const iframe = document.querySelector('#portal');
    
    // Original report dimensions
    const reportWidth = 1239;
    const reportHeight = 5740;
    
    // Get current screen width (accounting for any padding/margins)
    const currentWidth = iframe.offsetWidth;
    
    // Calculate proportional height
    const proportionalHeight = (currentWidth / reportWidth) * reportHeight;
    
    const tempSrc = parameter.startsWith('https://lookerstudio.google.com/embed/reporting')
      ? parameter
      : variant === 'Redbook'
        ? `https://lookerstudio.google.com/embed/reporting/bdff8788-2e37-43d4-9957-35d993373c9a/page/Hpd7D?params=%7B"df184":"include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580${parameter}"%7D`
        : `https://lookerstudio.google.com/embed/reporting/9fe9f1e8-8158-41e8-bbe2-e309e556b8bd/page/Hpd7D?params=%7B"df66":"include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580${parameter}"%7D`;
    
    iframe.style.height = `${Math.round(proportionalHeight)}px`;
    iframe.setAttribute('src', tempSrc);
    setCurrentIframeUrl(tempSrc);
  }, [parameter, variant]);

  // const customizeIframe2 = useCallback((url) => {
  //   const iframe = document.querySelector('#portal');
  //   iframe.setAttribute('src', url);
  //   setCurrentIframeUrl(url);
    
  //   // Check if the URL is from Looker Studio
  //   if (url.includes('lookerstudio.google.com')) {
  //     // Original report dimensions
  //     const reportWidth = 1239;
  //     const reportHeight = 5740;
      
  //     // Get current screen width
  //     const currentWidth = iframe.offsetWidth;
      
  //     // Calculate proportional height
  //     const proportionalHeight = (currentWidth / reportWidth) * reportHeight;
      
  //     iframe.style.height = `${Math.round(proportionalHeight)}px`;
  //   } else {
  //     // Use default height calculation for non-Looker Studio URLs
  //     iframe.style.height = window.innerHeight - 110 + 'px';
  //   }
  // }, []);
  const initializeGrantHopper = (iframe, retries = 3) => {
    const sendToken = () => {
      try {
        // console.log('Starting Grant Hopper initialization...');
        
        const tokenString = localStorage.getItem('sb-api-auth-token');  // Updated key
        // console.log('Token string exists:', !!tokenString);
        
        if (!tokenString) {
          console.error('No auth token found in localStorage');
          return false;
        }
        
        const tokenData = JSON.parse(tokenString);
        // console.log('Token data parsed:', {
        //   hasAccessToken: !!tokenData?.access_token,
        //   hasRefreshToken: !!tokenData?.refresh_token,
        //   expiresAt: tokenData?.expires_at
        // });
        
        if (!tokenData?.access_token) {
          console.error('Invalid token data:', tokenData);
          return false;
        }

        // console.log('Sending auth token to GrantHopper...', {
        //   targetOrigin: process.env.NODE_ENV === 'development'
        //     ? 'http://localhost:8788'
        //     : 'https://granthopper.iptalons.com',
        //   tokenExists: true,
        //   expiresAt: tokenData.expires_at
        // });
        
        iframe.contentWindow.postMessage({
          type: 'AUTH_TOKEN',
          token: tokenData.access_token,
          refresh_token: tokenData.refresh_token,
          expires_at: tokenData.expires_at
        }, process.env.NODE_ENV === 'development' 
          ? 'http://localhost:8788'
          : 'https://granthopper.iptalons.com');
        
        return true;
      } catch (error) {
        console.error('Error initializing Grant Hopper:', error);
        return false;
      }
    };
  
    let attempts = 0;
    const tryInit = () => {
      if (attempts >= retries) {
        console.error('Failed to initialize Grant Hopper after', retries, 'attempts');
        return;
      }
      
      if (!sendToken()) {
        attempts++;
        setTimeout(tryInit, 1000); // Retry after 1 second
      }
    };
  
    tryInit();
  };
  
  const customizeIframe2 = useCallback((url) => {
    const iframe = document.querySelector('#portal');
    
    let finalUrl = url;
    if (process.env.NODE_ENV === 'development' && url.includes('granthopper.iptalons.com')) {
      finalUrl = url.replace('https://granthopper.iptalons.com', 'http://localhost:8788');
    }

    iframe.setAttribute('src', finalUrl);
    setCurrentIframeUrl(finalUrl);
    
    if (finalUrl.includes('granthopper.iptalons.com') || finalUrl.includes('localhost:8788')) {
      iframe.onload = () => initializeGrantHopper(iframe);
    }
    
    // Rest of the sizing logic
    if (url.includes('lookerstudio.google.com')) {
      // Original report dimensions
      const reportWidth = 1239;
      const reportHeight = 5740;
      
      // Get current screen width
      const currentWidth = iframe.offsetWidth;
      
      // Calculate proportional height
      const proportionalHeight = (currentWidth / reportWidth) * reportHeight;
      
      iframe.style.height = `${Math.round(proportionalHeight)}px`;
    } else {
      // Use default height calculation for non-Looker Studio URLs
      iframe.style.height = window.innerHeight - 110 + 'px';
    }
  }, []);

  const fetchUserData = useCallback(async () => {
    const { data, error } = await supabase
      .from('iptalons_parameters')
      .select('*')
      .eq('email', user.email);

    if (error) {
      console.error('Error fetching data from Supabase:', error);
      return;
    }

    const userData = data[0];
    setCustomLinks(userData.custom_links || []);
    setDriveUrl(userData.drive_url || "");
    setParameter(userData.parameter || "");
    setVariant(userData.variant || "");
    setAccountType(userData.account_type || "paid");
    setTrialEndsAt(userData.trial_ends_at || null);

    // Check if user needs to accept terms
    const shouldShowTerms = (userData.email?.endsWith('@natcast.org') || userData.variant === "Natcast") && !userData.terms_accepted;
    setHasAcceptedTerms(!shouldShowTerms);
    setShowTermsModal(shouldShowTerms);

    if (user.email?.endsWith('@natcast.org') || userData.variant === "Natcast") {
      const grantHopperLink = userData.custom_links?.find(link => link.name === "Grant Hopper");
      if (grantHopperLink) {
        setSelectedDashboard("Grant Hopper");
      }
    }

    setIsReady(true);
  }, [user.email]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (user) {
      fetchUserData();
    }
  }, [user, fetchUserData]);

  useEffect(() => {
    const handleGrantHopperMessage = (event) => {
      if (event.origin !== 'https://granthopper.iptalons.com' || 
        event.origin !== 'http://localhost:8788') return;
      
      if (event.data.type === 'GRANT_HOPPER_AUTH_CHANGE') {
        // console.log('Grant Hopper auth state changed:', event.data.session);
        // Handle any necessary UI updates based on Grant Hopper's auth state
      }
    };

    window.addEventListener('message', handleGrantHopperMessage);
    return () => window.removeEventListener('message', handleGrantHopperMessage);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isReady) return;
    
    if (user.email?.endsWith('@natcast.org') || variant === "Natcast") {
      const grantHopperLink = customLinks.find(link => link.name === "Grant Hopper");
      if (grantHopperLink && selectedDashboard === "Grant Hopper") {
        customizeIframe2(grantHopperLink.url);
      }
    }
  // eslint-disable-next-line
  }, [isReady]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isReady && selectedDashboard === 'Dashboard') {
      customizeIframe();
    }
  // eslint-disable-next-line
  }, [isReady, selectedDashboard]);

  // Add this function to handle terms acceptance
  const handleTermsAccept = async () => {
    try {
      const { error } = await supabase
        .from('iptalons_parameters')
        .update({ terms_accepted: true }) // Only updating this one field
        .eq('email', user.email)
        .select();
  
      if (error) {
        console.error('Update error:', error);
        return;
      }
  
      setHasAcceptedTerms(true);
      setShowTermsModal(false);
    } catch (error) {
      console.error('Error in handleTermsAccept:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      // First clear any local storage items with the correct key
      localStorage.removeItem('sb-api-auth-token');
      
      // Attempt to sign out from Supabase
      const { error } = await supabase.auth.signOut();
      
      if (error && !error.message.includes('Auth session missing')) {
        console.error('Error signing out:', error.message);
      }
      
      // Force reload the page to clear any remaining state
      window.location.href = '/';
    } catch (err) {
      console.error('Error in handleSignOut:', err);
      // Force reload anyway as a fallback
      window.location.href = '/';
    }
  };

  const renderNavigationLinks = () => {
    if (!isReady || (!driveUrl && customLinks.length === 0)) {
      return (
        <div className="col" style={{ margin: "1%", display: 'flex', justifyContent: 'center' }}>
          <Card.Body style={{ paddingRight: "0px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <div className="navigation-links">
              {!isReady && <span style={{ color: '#FFFFFF' }}>Loading...</span>}
            </div>
          </Card.Body>
        </div>
      );
    }

    // Prepare navigation items
    let navItems = [];

    // Special case for natcast.org emails
    if (user.email.toLowerCase().endsWith("@natcast.org") || variant === "Natcast") {
      const grantHopperLink = customLinks.find(link => link.name === "Grant Hopper");
      if (grantHopperLink) {
        navItems.push(
          <span 
            key="grant-hopper"
            className={`nav-link ${selectedDashboard === 'Grant Hopper' ? 'active' : ''}`} 
            onClick={() => { setSelectedDashboard('Grant Hopper'); customizeIframe2(grantHopperLink.url); }}
          >
            Grant Hopper
            {selectedDashboard === 'Grant Hopper' && <span className="underline"></span>}
          </span>
        );
      }
    }

    // Add RedBook link
    navItems.push(
      <span 
        key="redbook"
        className={`nav-link ${selectedDashboard === 'Dashboard' ? 'active' : ''}`} 
        onClick={() => { setSelectedDashboard('Dashboard'); customizeIframe(); }}
      >
        RedBook
        {selectedDashboard === 'Dashboard' && <span className="underline"></span>}
      </span>
    );

    // Add Google Drive if exists
    if (driveUrl) {
      navItems.push(
        <span 
          key="drive"
          className={`nav-link ${selectedDashboard === 'Google Drive' ? 'active' : ''}`} 
          onClick={() => { setSelectedDashboard('Google Drive'); customizeIframe2(driveUrl); }}
        >
          Google Drive
          {selectedDashboard === 'Google Drive' && <span className="underline"></span>}
        </span>
      );
    }

    // Add remaining custom links (excluding Grant Hopper if it's already at the start)
    customLinks.forEach((link, index) => {
      if (!((user.email.toLowerCase().endsWith("@natcast.org") || variant === "Natcast") && link.name === "Grant Hopper")) {
        navItems.push(
          <span 
            key={index}
            className={`nav-link ${selectedDashboard === link.name ? 'active' : ''}`} 
            onClick={() => { setSelectedDashboard(link.name); customizeIframe2(link.url); }}
          >
            {link.name}
            {selectedDashboard === link.name && <span className="underline"></span>}
          </span>
        );
      }
    });

    return (
      <div className="col" style={{ margin: "1%", display: 'flex', justifyContent: 'center' }}>
        <Card.Body style={{ paddingRight: "0px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <div className="navigation-links">
            {navItems}
          </div>
        </Card.Body>
      </div>
    );
  };

  return (
    <div className="container-fluid" style={{ overflowX: "hidden", marginTop: "0%", marginBottom: "0.5%", paddingLeft: "0", paddingRight: "0" }}>
      {accountType === 'trial' && (
        <TrialBanner trialEndsAt={trialEndsAt} />
      )}
      {user.email === 'admin@trial.edu' && (
        <ProgressBanner />
      )}
      <div className="row custom-row" style={{ backgroundColor: "#FFFFFF", borderColor: "#FFFFFF" }}>
        <div className="col-2" style={{ margin: "1%", marginLeft: "0.25%" }}>
          <img src={logo} width={"240px"} style={{ float: "left", marginRight: "24px" }} alt="Logo" />
        </div>
        {renderNavigationLinks()}
        <div className="col-2" style={{ margin: "1%", marginRight: "0%", paddingTop: "16px" }}>
          <Card.Body style={{ paddingRight: "0px", textAlign: "end", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <Button className="me-md-2 refresh-button" style={{ margin: '0%', marginLeft: '0%', backgroundColor: '#1C6941', borderColor: '#1C6941', color: 'white', padding: '2px 6px' }} size="sm" onClick={() => customizeIframe()}>
              <i className="bi bi-arrow-repeat" style={{ fontSize: '18px', fontWeight: 'bold' }}></i>
            </Button>
            {user && user.email === "admin@iptalons.com" && (
              <Button 
                className="me-md-2" 
                style={{ margin: '0%', marginLeft: '0%', backgroundColor: '#787A7B', borderColor: '#787A7B', color: '#FFFFFF', padding: '2px 6px', display: 'flex', alignItems: 'center' }} 
                size="sm" 
                onClick={() => setShowAdminModal(true)}
              >
                <i className="bi bi-person-gear" style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '4px', verticalAlign: 'middle' }}></i>
                <span style={{ lineHeight: '18px' }}>Admin</span>
              </Button>
            )}
            <Button className="signout-button" style={{ margin: '0%', marginLeft: '0%', backgroundColor: '#72ab32', borderColor: '#72ab32', color: '#FFFFFF', padding: '2px 6px', display: 'flex', alignItems: 'center' }} size="sm" onClick={handleSignOut}>
              <i className="bi bi-box-arrow-right" style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '4px', verticalAlign: 'middle' }}></i>
              <span style={{ lineHeight: '18px' }}>Sign Out</span>
            </Button>
          </Card.Body>
        </div>
      </div>
      <div className="row">
        <div className="position-relative w-100" style={{ overflow: 'hidden' }}>
          <iframe 
            title="Dashboard Portal" 
            id="portal" 
            width="100%" 
            height="6060"
            style={{ display: 'block' }} 
          />
          {currentIframeUrl.includes('lookerstudio.google.com') && (
            <div 
              className="position-absolute bottom-0 start-0 end-0"
              style={{ 
                height: '24px',
                zIndex: 1000,
                cursor: 'default',
                background: 'linear-gradient(to right, #1c6941, #72ab32)'
              }} 
            />
          )}
        </div>
      </div>

      {/* Admin Modal */}
      <Modal show={showAdminModal} onHide={() => setShowAdminModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Admin Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdminInterface />
        </Modal.Body>
      </Modal>
      {/* {(user.email === "users who signed up for T2BI") && (
        <>
          <Modal show={showChat} onHide={() => setShowChat(false)}>
            <Modal.Dialog style={{ position: 'fixed', bottom: '0px', right: '20px', maxWidth: '800px', borderRadius: '5px' }}>
              <Modal.Header closeButton>
                <Button onClick={() => window.open(`https://chat-widget.text2bi.com/?token=${idToken}`, '_blank')} style={{ marginRight: 'auto', backgroundColor: '#f8f9fa', borderColor: '#f8f9fa', color: '#343a40' }}>
                  <i className="bi bi-box-arrow-up-right"></i>
                </Button>
              </Modal.Header>
              <Modal.Body style={{ height: '600px', width: '450px', padding: 0, borderRadius: '5px' }}>
                <iframe title="Chat Portal" style={{ width: "100%", height: "100%" }} src={`https://chat-widget.text2bi.com/?token=${idToken}`}></iframe>
              </Modal.Body>
            </Modal.Dialog>
          </Modal>
          <Button onClick={() => setShowChat(true)} style={{ backgroundColor: '#3BA37F', borderColor: '#3BA37F', color: 'white', borderRadius: '50%', width: '60px', height: '60px', position: 'fixed', bottom: '20px', right: '20px', fontSize: '24px' }}>
            <i className="bi bi-chat-dots"></i>
          </Button>
        </>
      )} */}
      <div className="container" style={{ marginTop: "1.5%" }}>
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-6 border-top">
          <div className="col-md-4 d-flex align-items-center">
            <span className="mb-3 mb-md-0 text-muted">&copy; 2025 IPTalons, Inc. All rights reserved.</span>
          </div>
        </footer>
      </div>
      <TermsOfUseModal 
        show={showTermsModal} 
        onAccept={handleTermsAccept}
      />
    </div>
  );
};

export default Home;