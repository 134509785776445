import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./components/LoginPage";
import ResetPassword from "./components/ResetPassword";
import SetupPassword from "./components/SetupPassword";
import Home from "./components/HomePage";
import supabase from "./services/supabase";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleInviteFlow = async () => {
      try {
        // First check if we have a session
        const { data: { session: existingSession } } = await supabase.auth.getSession();
        
        if (!existingSession) {
          const urlParams = new URLSearchParams(window.location.search);
          const confirmationUrl = urlParams.get('confirmation_url');
          
          if (confirmationUrl) {
            const decodedUrl = decodeURIComponent(confirmationUrl);
            const confirmationUrlParams = new URL(decodedUrl).searchParams;
            const token = confirmationUrlParams.get('token');
            const type = confirmationUrlParams.get('type');
            
            if (token) {
              const { data: { session }, error } = await supabase.auth.verifyOtp({
                token_hash: token,
                type: type || 'invite' // Handle both recovery and invite types
              });
              
              if (error) throw error;
              
              // Set user if verification successful
              setUser(session?.user || null);
              
              // Clear the URL parameters and redirect based on type
              if (type === 'recovery') {
                window.history.replaceState(null, '', '/reset-password');
              } else {
                window.history.replaceState(null, '', '/setup-password');
              }
              return;
            }
          }
        } else {
          setUser(existingSession.user);
        }
      } catch (err) {
        console.error('Verification flow error:', err);
      } finally {
        setLoading(false);
      }
    };

    const getSession = async () => {
      try {
        await handleInviteFlow();
        const { data: { session } } = await supabase.auth.getSession();
        setUser(session?.user || null);
      } catch (err) {
        console.error('Session error:', err);
      } finally {
        setLoading(false);
      }
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user || null);
    });

    return () => {
      if (authListener && typeof authListener.unsubscribe === 'function') {
        authListener.unsubscribe();
      }
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route 
            path="/setup-password" 
            element={<SetupPassword />} 
          />
          <Route 
            path="/reset-password" 
            element={<ResetPassword />} 
          />
          <Route 
            path="/" 
            element={
              user ? <Home user={user} /> : <Login />
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
